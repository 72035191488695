import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout/layout';
import { H1, H2, P, ButtonLink } from '../components';
import InstagramPreview from '../components/instagram/preview';
import ContactForm from '../components/forms/contact';

export default function Home() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="w-full min-h-screen">
        <H1 srOnly>We create emotional experiences through artisanal Belgian beer</H1>
        <Carousel responsive={{ uniform: { breakpoint: { min: 0, max: 4000 }, items: 1 } }} showDots={true} ssr>
          <div className="w-full min-h-screen lg:pb-20 pt-36 md:pt-48 lg:pt-60 bg-saison-background">
            <div className="relative flex flex-col justify-center items-center gap-6 md:gap-8 lg:gap-10 xl:gap-12 text-center">
              <div className="w-48 lg:w-64">
                <StaticImage
                  placeholder="blurred"
                  src="../assets/images/beers/saison_title.png"
                  alt="Saison"
                  layout="fullWidth"
                />
              </div>
              <P className="text-xl font-bold mobile:leading-normal override:text-saison" style={{ maxWidth: '500px' }}>
                Deep amber earthy ale with smooth toffee notes, and fruity aromas.
              </P>
              <ButtonLink
                to="/beers/saison"
                className="z-30"
                overrideBtnClassName="override:text-saison override:border-saison"
                main
              >
                DISCOVER
              </ButtonLink>
              <div
                className="absolute opacity-30 lg:opacity-100 mobile:inset-0 mobile:top-36 lg:right-0 xl:right-10 2xl:right-14 w-11/12 lg:w-2/12 xl:w-3/12 2xl:w-4/12 max-w-4xl"
                style={{ minWidth: '380px' }}
              >
                <div className="w-full flex flex-col items-center justify-center">
                  <div className="w-full">
                    <StaticImage
                      placeholder="blurred"
                      src="../assets/images/index/saison_art.png"
                      alt="Bottle painting of the Saison beer"
                      layout="fullWidth"
                    />
                    <P className="hidden lg:block override:text-primary override:font-semibold font-subHeader uppercase">
                      Refreshing Reward
                    </P>
                  </div>
                </div>
              </div>
              <div
                className="mobile:-mt-6 mb-5 lg:absolute lg:top-0 lg:left-20 xl:left-40 2xl:left-60 w-4/12 md:w-2/12 lg:w-60 xl:w-68 2xl:w-80 max-w-md"
                style={{ minWidth: '100px' }}
              >
                <StaticImage
                  placeholder="blurred"
                  src="../assets/images/index/saison_bottle.png"
                  alt="A Saison beer bottle"
                  layout="fullWidth"
                />
              </div>
            </div>
          </div>
          <div className="relative w-full min-h-screen lg:pb-20 pt-36 md:pt-48 lg:pt-60 bg-scotch-background">
            <div className="relative flex flex-col justify-center items-center gap-6 md:gap-8 lg:gap-10 xl:gap-12 text-center">
              <div className="w-48 lg:w-64">
                <StaticImage
                  placeholder="blurred"
                  src="../assets/images/beers/scotch_title.png"
                  alt="Scotch"
                  layout="fullWidth"
                />
              </div>
              <P className="text-xl font-bold mobile:leading-normal override:text-scotch" style={{ maxWidth: '500px' }}>
                Cloudy, zesty brew with hints of coriander and citrus touch of dried orange peel.
              </P>
              <ButtonLink
                to="/beers/scotch"
                className="z-30"
                overrideBtnClassName="override:text-scotch override:border-scotch"
                main
              >
                DISCOVER
              </ButtonLink>
              <div
                className="absolute opacity-30 lg:opacity-100 mobile:inset-0 mobile:top-36 lg:right-0 xl:right-10 2xl:right-14 w-11/12 lg:w-2/12 xl:w-3/12 2xl:w-4/12 max-w-4xl"
                style={{ minWidth: '300px' }}
              >
                <div className="w-full flex flex-col items-center justify-center">
                  <div className="w-full">
                    <StaticImage
                      placeholder="blurred"
                      src="../assets/images/index/scotch_art.png"
                      alt="Bottle painting of the Scotch beer"
                      layout="fullWidth"
                    />
                    <P className="hidden lg:block override:text-primary override:font-semibold font-subHeader uppercase">
                      Essential Indulgence
                    </P>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center lg:block">
              <div className="lg:absolute mb-20 mt-28 lg:mb-5 lg:left-5 lg:bottom-0 2xl:bottom-20 w-10/12 md:w-7/12 mb:mb-20 lg:w-1/2 xl:w-4/12 2xl:w-5/12 max-w-4xl">
                <StaticImage
                  placeholder="blurred"
                  src="../assets/images/index/scotch_bottle.png"
                  alt="A Scotch beer bottle"
                  layout="fullWidth"
                />
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      <div className="relative min-h-screen w-full text-center flex flex-col gap-10 justify-center items-center py-10">
        <div
          className="absolute inset-0 opacity-20"
          style={{ backgroundImage: `url("/images/index/background_forms.jpg")`, backgroundRepeat: 'repeat-y' }}
        />
        <H2 className="text-4xl font-medium override:text-primary z-20">{t('lpDescriptionTitle')}</H2>
        <P className="font-medium text-xl leading-relaxed z-20">{t('lpDescription')}</P>
        <P className="font-medium text-xl leading-relaxed z-20">{t('lpDescriptionTwo')}</P>
        <ButtonLink to="/about" main className="z-20">
          ABOUT
        </ButtonLink>
      </div>
      <div className="w-full min-h-screen">
        <Carousel responsive={{ uniform: { breakpoint: { min: 0, max: 4000 }, items: 1 } }} showDots={true} ssr>
          <div className="relative min-h-screen w-full text-center py-10">
            <div className="w-screen">
              <StaticImage
                src="../assets/images/index/three_glasses_pouring.jpg"
                alt="Pouring beer into three glasses"
                className="rounded-sm shadow-sm"
              />
            </div>
            <div className="lg:opacity-80 p-2 bg-primary z-20 w-full text-center lg:absolute top-10 flex flex-col justify-center items-center">
              <P className="font-medium z-20 lg:max-w-xl override:m-0 p-1">
                IT&#39;S DISTASTEFUL TO DRINK THE BEER STRAIGHT OUT OF THE BOTTLE.
              </P>
              <P className="font-medium z-20 lg:max-w-xl override:m-0 p-1">POUR IT IN A GLASS.</P>
              <P className="font-medium z-20 lg:max-w-xl override:m-0 p-1">WHY DENY YOURSELF THIS VISUAL DELIGHT.</P>
              <ButtonLink to="/glassware" main className="z-20 lg:max-w-xl override:m-0">
                KNOW MORE
              </ButtonLink>
            </div>
          </div>
          <div className="relative min-h-screen w-full text-center py-10">
            <div className="w-screen">
              <StaticImage
                src="../assets/images/index/swag.jpg"
                alt="A shot of Loka Brews swag on a table including a beer glass, t-shirts, and marketing materials"
                className="lg:absolute inset-0"
                layout="fullWidth"
              />
            </div>
            <div className="w-full flex lg:absolute right-48 top-48 flex-col justify-center items-center max-w-lg">
              <P className="font-medium text-xl leading-relaxed z-20 override:lg:text-white">
                Experience culture, emotions, and art of brewing
              </P>
            </div>
          </div>
        </Carousel>
      </div>
      <ContactForm className="min-h-screen w-full text-center flex flex-col gap-10 justify-center items-center py-10" />
      <InstagramPreview className="min-h-screen w-full text-center flex flex-col gap-10 justify-center items-center py-10 px-10" />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
