import React, { useEffect, useState } from 'react';
import { InstagramPost, InstagramResponseData } from '../../types';
import { H1, OutboundLink } from '../../components/';

interface InstagramPreviewProps {
  className?: string;
}

const InstagramPreview: React.FC<InstagramPreviewProps> = ({ className = '' }) => {
  const [gramz, setGramz] = useState<InstagramPost[]>([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const data: InstagramResponseData = await fetch('/api/instagram').then((response) => response.json());
        if (!data.instagramImages.length) {
          setHasError(true);
          return;
        }
        setHasError(false);
        setGramz(data.instagramImages);
      } catch (error) {
        console.log(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (hasError || isLoading) {
    return null;
  }

  return (
    <div className={className}>
      <H1 asH2>Follow us on Instagram</H1>
      <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
        {gramz.map((gram) => {
          return (
            <OutboundLink href={gram.postUrl} key={gram.id} className="block w-full">
              <div className="relative transform hover:scale-105 w-full">
                <div className="absolute inset-0 bg-black opacity-0 transform hover:bg-opacity-30 hover:opacity-100 z-20 flex flex-col items-center justify-center">
                  <p className="text-white font-semibold">Likes: {gram.likes}</p>
                  <p className="text-white font-semibold">View on Instagram</p>
                </div>
                <img src={gram.imageBase64} alt={gram.caption} loading="lazy" className="w-full h-full" />
              </div>
            </OutboundLink>
          );
        })}
      </div>
    </div>
  );
};

export default InstagramPreview;
